<footer class="text-lg-start">
  <div *ngIf="showTopFooter" class="top-footer">
    <!-- Grid container -->
    <div class=" container p-4">
      <!--Grid row-->
      <div class="row">

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase">Footer Content</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="./" class="">Lorem ipsum</a>
            </li>
            <li>
              <a routerLink="./" class="">Ut facilisis</a>
            </li>
            <li>
              <a routerLink="./" class="">Aenean sit</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
          <h5 class="text-uppercase">Footer Content</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="./" class="">Suspendisse potenti</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-12 mb-4 mb-md-0">
          <h5 class="text-uppercase">Footer Content</h5>

          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste atque ea quis
            molestias. Fugiat pariatur maxime quis culpa corporis vitae repudiandae aliquam
            voluptatem veniam, est atque cumque eum delectus sint!
          </p>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="bottom-footer p-1 d-flex justify-content-center align-items-center text-white">
    <div class="content-container">
      <a href="https://mtsu.edu"><img src="assets/images/mtsu-logo-white.png"
        alt="Middle Tennessee State University" class="logo mr-5 pt-3 float-left"></a>
      <p class="m-0 pt-3">
        <a class="text-white"
           href="http://www.dspace.org/">{{ 'footer.link.dspace' | translate}}</a>
        {{ 'footer.copyright' | translate:{year: dateObj | date:'y'} }}
        <a class="text-white"
           href="https://www.lyrasis.org/">{{ 'footer.link.lyrasis' | translate}}</a> - ICT Support by
           <a class="text-white" href="https://www.4science.it/en"><img src="assets/images/4science-white-logo-small.png" alt="4Science" /></a>
      </p>
      <ul class="footer-info list-unstyled small d-flex justify-content-center mb-0">
        <li>
          <a class="text-white" href="#"
             (click)="showCookieSettings()">{{ 'footer.link.cookies' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
             href="http://library.mtsu.edu/privacy-statement" target="_blank">{{ 'footer.link.privacy-policy' | translate}}</a>
        </li>
      </ul>
    </div>
  </div>
  <!-- Copyright -->
</footer>
